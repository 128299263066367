import { Component, HostBinding, OnInit } from '@angular/core';
import { ToastService } from './_services';
import { Router, NavigationError } from '@angular/router';
import Swal from 'sweetalert2';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col bg-white lg:bg-defaultBackground';
  public swal: typeof Swal;

  constructor(
    private toastService: ToastService,
    private router: Router
  ) {
    this.swal = Swal.mixin({
      toast: true,
      position: 'bottom-start',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      showCloseButton: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationError)).subscribe((event: NavigationError) => {
      if (event.error instanceof Error && event.error.name === 'ChunkLoadError') {
        console.warn('Reloading due to load chunk file error');
        window.location.assign(event.url || '/');
      }
    });
  }
  /*
    panelClass = slick-custom-snackbar-success
    panelClass = slick-custom-snackbar-error
    panelClass = slick-custom-snackbar-warning
   */
  ngOnInit(): void {
    this.toastService.queue.subscribe((value) => {
      switch (value.panelClass) {
        case 'slick-custom-snackbar-error':
          value.panelClass = 'error';
          break;
        case 'slick-custom-snackbar-warning':
          value.panelClass = 'warning';
          break;
        default:
          value.panelClass = 'success';
          break;
      }
      this.swal.fire(value.message, '', value.panelClass || 'success');
    });
  }
}
